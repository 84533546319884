import Link from 'next/link';
import style from './index.module.scss';
import { If, IntrinsicProps, RouterLink, createComponent } from 'react-commons';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';

interface MobileUpsellBannerProps extends IntrinsicProps {
}

export default createComponent<MobileUpsellBannerProps>('MobileUpsellBanner', { style }, function MobileUpsellBanner ({ className }, props) {
  const [ isPremiumUser, isLoading ] = useIsPremiumUser();

  return (
    <>
      {
        If(isLoading, () => (
          <div className={className}>
            <img
              src='/images/shockwaveUnlimited.svg'
              alt='Shockwave Unlimited'
            />
            <span>&nbsp;</span>
          </div>
        )).ElseIf(isPremiumUser, () => (
          <Link href='/games-for-pc'>
            <div className={className}>
              <img
                src='/images/shockwaveUnlimited.svg'
                alt='Shockwave Unlimited'
              />
              <span>
                Did you know your subscription gives you access to even more games on Desktop?
              </span>
            </div>
          </Link>
        )).Else(() => (
          <Link href='/unlimited'>
            <div className={className}>
              <img
                src='/images/shockwaveUnlimited.svg'
                alt='Shockwave Unlimited'
              />
              <span>
                Subscribe to access past daily games!
              </span>
            </div>
          </Link>
        )).EndIf()
      }
    </>
  );
});
