import Link from 'next/link';
import style from './index.module.scss';
import { ButtonLink, HTMLContent, IconFA, If, IntrinsicProps, createComponent } from 'react-commons';

interface MobileGameButtonProps extends IntrinsicProps {
  thumbnail: string
  title: string
  description?: string
  slug?: string
  href?: string
  autoHeight?: boolean
  largeImage?: boolean
  imageHasShadow?: boolean
}

const classStates = [
  'autoHeight',
  'largeImage',
  'imageHasShadow',
];

export default createComponent<MobileGameButtonProps>('MobileGameButton', { style, classStates }, function MobileGameButton ({ className }, props) {
  const url = props.slug
    ? `/gamelanding/mobile/${props.slug}`
    : props.href.replace('gamelanding', 'gamelanding/mobile');
  
  return (
    <div className={className}>
      <Link href={url}>
        {
          If(props.thumbnail, () => (
            <img
              src={props.thumbnail}
              alt={`${props.title} thumbnail`}
            />
          )).EndIf()
        }
      </Link>
      <Link href={url}>
        <h2>{props.title}</h2>
      </Link>
      {
        If(props.description, () => (
          <HTMLContent html={props.description} />
        )).EndIf()
      }
      <ButtonLink small href={url} secondary>
        Play Now
      </ButtonLink>
    </div>
  );
});
